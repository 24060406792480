import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock(_component_Select, {
    onOpenSelect: _ctx.onOpenSelectAddressType,
    onCloseSelect: _ctx.onCloseSelectAddressType,
    isOpenMenu: _ctx.isOpenSelectAddressType,
    value: _ctx.modelValue,
    onChooseSelect: _ctx.onSelectAddressType,
    dataSelect: _ctx.listAddressType,
    class: "w-full whitespace-no-wrap my-2 z-10",
    isError: _ctx.isError,
    errorCaption: "Tipe Alamat wajib diisi",
    isDisabled: _ctx.isDisabled
  }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect", "isError", "isDisabled"]))
}