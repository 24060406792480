
import { Vue, Options, prop } from "vue-class-component";

class Props {
  modelValue = prop<any>({});
  isError = prop<boolean>({
    type: Boolean,
    default: false
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class AddressType extends Vue.with(Props) {
  get listAddressType() {
    const defaultChoices: any = [
      {
        name: "Tipe Alamat",
        value: ""
      },
      {
        name: "Rumah",
        value: "home"
      },
      {
        name: "Kantor",
        value: "office"
      }
    ];

    return defaultChoices;
  }

  isOpenSelectAddressType = false;
  onOpenSelectAddressType() {
    this.isOpenSelectAddressType = true;
  }

  onCloseSelectAddressType() {
    this.isOpenSelectAddressType = false;
  }

  isErrorSelectAddressType = false;
  onSelectAddressType(name: string, value: string) {
    this.$emit("update:modelValue", value);
    this.isErrorSelectAddressType = !value;
  }
}
